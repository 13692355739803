<template>
    <div>
        <!--table student-->
        <div class="d-flex justify-content-between align-items-center">
            <div>
                <b-tabs pills v-model="tab" class="border-2">
                    <b-tab title="مرحلة التعليم الجامعي" active> </b-tab>
                    <b-tab title="مرحلة التعليم المدرسي"> </b-tab>
                </b-tabs>
            </div>
            <h4>
                عدد الزوار:
                <b-badge pill variant="primary">
                    {{ visitCountList }}
                </b-badge>
            </h4>
        </div>
        <ek-table
            :items="studentList"
            :columns="StudentsCols"
            @details="goToDetails"
            @delete-selected="deleteStudents"
            :row-style-class="rowStyleClassFn"
            deleteBtn
        >
            <template slot="items.dateCreated" slot-scope="{ value }">
                {{ value ? new Date(value).toLocaleDateString() : "" }}
            </template>

            <template slot="items.endDate" slot-scope="{ value }">
                {{ value ? new Date(value).toLocaleDateString() : "_" }}
            </template>

            <template slot="items.isSub" slot-scope="{ value }">
                <StatusBadge :value="value" :options="statusStudentList" />
            </template>
        </ek-table>
    </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import StatusBadge from "@global/components/StatusBadge.vue";

export default {
    components: {
        StatusBadge,
    },
    computed: {
        ...mapState({
            visitCountList: ({ students }) => students.visitCountList,
            students: ({ students }) => students.students,
            StudentsCols: ({ students }) => students.StudentsCols,
            statusStudentList: (state) => state.students.statusStudent,
        }),
        ...mapGetters(["studentList"]),
    },
    data: () => ({ tab: 0 }),
    methods: {
        ...mapActions(["getStudentsList", "deleteStudents", "getGetUnRead"]),
        goToDetails({ row }) {
            let rowDotName = row.name;
            this.$router.push({
                path: `students/${row.id}`,
                query: { name: rowDotName },
            });
        },
        rowStyleClassFn(row) {
            return row.isBlocked ? "bg-danger" : "";
        },
    },
    beforeDestroy() {
        this.$store.commit("Set_Student_Dto");
    },
    created() {
        this.getStudentsList({ StudentType: 0 });
        this.getGetUnRead();
    },
    watch: {
        tab: function (newTab) {
            if (newTab == 0) {
                this.getStudentsList({ StudentType: 0 });
                this.getNamesUniversity(0);
            } else {
                this.getStudentsList({ StudentType: 1 });
                this.getNamesUniversity(1);
            }
        },
    },
};
</script>
<style>
.vgt-checkbox-col {
    background: transparent !important;
}
</style>
